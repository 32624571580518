<template>
  <div class="theme-bg fill-height">
    <div class="pa-4">
      <div class="d-flex pt-4 pb-3 align-center">
        <h2 class="primary--text font-weight-regular">
          รายละเอียดการซ่อมบำรุง
        </h2>
        <v-btn
          color="primary"
          class="ml-auto"
          @click="exportRepairDialog = !exportRepairDialog"
        >
          <v-icon left>mdi-export-variant</v-icon>
          ส่งออก
        </v-btn>
      </div>
      <div class="d-flex align-center mt-4">
        <v-text-field
          v-model="name"
          name="search"
          id="id"
          solo
          dense
          hide-details
          placeholder="ชื่อสะพาน หรือ แผนซ่อมบำรุง"
          prepend-inner-icon="mdi-magnify"
          @keydown.enter="getMethodBridgeRepairList"
        ></v-text-field>
        <v-btn
          class="primary elevation-2 white--text rounded ml-2"
          icon
          tile
          @click="filterMaintenanceRepair = true"
        >
          <v-icon>mdi-filter-variant</v-icon>
        </v-btn>
      </div>
      <div class="mt-4">
        <v-data-table
          class="elevation-2 rounded row-pointer data-repair-table"
          :headers="headers"
          :items="items"
          :footer-props="{
            'items-per-page-text': 'จำนวนแถว'
          }"
          sort-by="budget_year"
          sort-desc
          :loading="isLoading"
          @click:row="openEditMaintenanceMethodDialog"
        >
          <!-- <template v-slot:item.km="{ item }">
            {{ getKm(item.km) }}
          </template> -->
          <template v-slot:item.status="{ item }">
            <div v-if="item.ref_bridge_repair_status_id === '1'">
              <v-chip class="white--text" :color="'green'" small>
                รอจัดสรร
              </v-chip>
            </div>
            <div v-else>
              <v-chip class="white--text" :color="'yellow'" small>
                ดำเนินการ
              </v-chip>
            </div>
          </template>
          <template v-slot:item.price="{ item }">
            {{ parseFloat(item.sum_price || 0).toLocaleString() }}
          </template>
          <template v-slot:item.name="{ item }">
            {{ item.name || '(ไม่ระบุชื่อ)' }}
          </template>
          <template v-slot:item.bridge_name="{ item }">
            {{
              `${item.bridge_name} ${
                item.direction_name ? `(${item.direction_name})` : ''
              }`
            }}
          </template>
          <template v-slot:item.km="{ item }">
            {{ `${getKm(item.km_start || 0)} - ${getKm(item.km_end || 0)}` }}
          </template>
          <template v-slot:item.bhi="{ item }">
            {{ parseFloat(item.bhi || 0).toLocaleString() }}
          </template>
          <template v-slot:item.nbes_29_value="{ item }">
            {{ parseFloat(item.nbes_29_value || 0).toLocaleString() }}
          </template>
          <!-- <template v-slot:item.nbes_19_value="{ item }">
            {{ parseFloat(item.nbes_19_value || 0).toLocaleString() }}
          </template> -->
          <template v-slot:item.nbes_28A_value="{ item }">
            {{ parseFloat(item.nbes_28A_value || 0).toLocaleString() }}
          </template>
          <template v-slot:item.nbes_27_value="{ item }">
            {{ parseFloat(item.nbes_27_value || 0) }}
          </template>
        </v-data-table>
      </div>
    </div>
    <EditMaintenanceMethodDialog
      v-model="editMaintenanceMethodDialog"
      :repairId="repairId"
      @confirm="confirmEdit"
      @close="editMaintenanceMethodDialog = false"
    />
    <FilterMaintenanceRepair
      v-model="filterMaintenanceRepair"
      @filter="filterMaintenance"
      @close="filterMaintenanceRepair = false"
    />
    <ExportRepairDialog
      v-model="exportRepairDialog"
      @close="exportRepairDialog = false"
    />
  </div>
</template>

<script>
import utils from '@/assets/js/utils'
import EditMaintenanceMethodDialog from '../components/repair/dialog/EditRepairDialog.vue'
import FilterMaintenanceRepair from '../components/repair/dialog/FilterMaintenanceRepairDialog.vue'
import ExportRepairDialog from '@/components/repair/dialog/ExportRepairDialog.vue'
export default {
  components: {
    EditMaintenanceMethodDialog,
    FilterMaintenanceRepair,
    ExportRepairDialog
  },
  data: () => ({
    getKm: utils.getKm,
    exportRepairDialog: false,
    headers: [
      {
        text: 'ปีงบประมาณ',
        align: 'center',
        value: 'budget_year',
        class: 'primary rounded-tl white--text text-no-wrap'
      },
      {
        text: 'รหัสสายทาง',
        align: 'center',
        sortable: false,
        value: 'road_code',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'กม.',
        align: 'center',
        sortable: false,
        value: 'km',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'ชื่อสะพาน',
        sortable: false,
        value: 'bridge_name',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'ชื่อแผนซ่อมบำรุง',
        sortable: false,
        value: 'name',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'BHI',
        align: 'center',
        sortable: false,
        value: 'bhi',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'ADT',
        align: 'center',
        sortable: false,
        value: 'nbes_29_value',
        class: 'primary white--text text-no-wrap'
      },
      // {
      //   text: 'Detour',
      //   align: 'center',
      //   sortable: false,
      //   value: 'nbes_19_value',
      //   class: 'primary white--text '
      // },
      {
        text: 'จำนวนเลน',
        align: 'center',
        sortable: false,
        value: 'nbes_28A_value',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'อายุ (ปี)',
        align: 'center',
        sortable: false,
        value: 'nbes_27_value',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'ราคารวม (บาท)',
        sortable: false,
        align: 'end',
        value: 'price',
        class: 'primary white--text text-no-wrap'
      },
      {
        text: 'สถานะ',
        sortable: false,
        align: 'center',
        value: 'status',
        class: 'primary white--text rounded-tr'
      }
    ],
    items: [],
    editMaintenanceMethodDialog: false,
    filterMaintenanceRepair: false,
    isLoading: true,
    repairId: null,
    budgetYear: '',
    name: '',
    filter: {}
  }),
  methods: {
    async getMethodBridgeRepairList () {
      this.isLoading = true
      this.items = []
      const filter = { ...this.filter }
      filter.name = this.name
      const items = await this.$store.dispatch(
        'repair/getMethodBridgeRepairList',
        { filter }
      )
      this.items = items
      this.isLoading = false
    },
    openEditMaintenanceMethodDialog (repair) {
      this.repairId = parseInt(repair.id)
      this.editMaintenanceMethodDialog = true
    },
    confirmEdit () {
      this.getMethodBridgeRepairList()
      this.editMaintenanceMethodDialog = false
    },
    filterMaintenance (filter) {
      this.filter = filter
      this.getMethodBridgeRepairList()
      this.filterMaintenanceRepair = false
    }
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>

<style>
.v-data-table.data-repair-table
  .v-data-table-header
  th.sortable.active
  .v-data-table-header__icon {
  color: white;
}
.v-data-table.data-repair-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
  color: rgba(255, 255, 255, 0.5);
}
</style>
