<template>
  <div>
    <FilterTemplateDialog
      v-model="isOpenDialog"
      title="ค้นหาขั้นสูง"
      @clear="clearForm()"
      @close="close()"
      @confirm="confirm()"
    >
      <div class="pa-4">
        <v-form
          ref="form"
          lazy-validation
          class="pt-0"
          enctype="multipart/form-data"
        >
          <v-row align="center">
            <v-col
              v-for="(form, formindex) in formFilterField"
              :key="formindex"
              cols="12"
              :sm="form.col"
            >
              <v-row align="center">
                <v-col
                  class="form-text"
                  :class="$vuetify.breakpoint.xs && 'pb-0'"
                  cols="12"
                  :sm="form.coltext"
                >
                  {{ form.name }}
                </v-col>
                <v-col
                  :class="$vuetify.breakpoint.xs && 'pt-0'"
                  cols="12"
                  :sm="form.colfield"
                >
                  <v-select
                    v-if="form.model === 'ref_maintenance_id'"
                    v-model="filterForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details="auto"
                    :items="maintenanceByGroup"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    clearable
                    :rules="form.isrequired ? [rules.required] : []"
                    :no-data-text="
                      form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                    "
                  ></v-select>
                  <v-select
                    v-else-if="form.model === 'depot_code'"
                    v-model="filterForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details
                    :no-data-text="
                      form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                    "
                    :items="depotByDistrict"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    clearable
                  ></v-select>
                  <v-text-field
                    v-else-if="form.type === 'text'"
                    v-model="filterForm[form.model]"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <v-text-field
                    v-else-if="form.type === 'number'"
                    v-model="filterForm[form.model]"
                    class="right-input"
                    type="number"
                    :placeholder="form.placeholder ? form.placeholder : '0'"
                    min="0"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                  <v-select
                    v-else-if="form.model === 'budget_year'"
                    v-model="filterForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details
                    :items="itemLists[form.select.name]"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    :no-data-text="
                      form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                    "
                  ></v-select>
                  <v-select
                    v-else-if="form.type === 'select'"
                    v-model="filterForm[form.model]"
                    placeholder="เลือก"
                    outlined
                    dense
                    hide-details
                    :no-data-text="
                      form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                    "
                    :items="itemLists[form.select.name]"
                    :item-text="form.select['item-text']"
                    :item-value="form.select['item-value']"
                    clearable
                  ></v-select>
                  <v-autocomplete
                    v-else-if="form.type === 'autocomplete'"
                    v-model="filterForm[form.model]"
                    multiple
                    outlined
                    dense
                    clearable
                    placeholder="เลือก"
                    hide-details="auto"
                    :no-data-text="
                      form.nodata ? form.nodata : `ไม่มี ${form.name} ให้เลือก`
                    "
                    :items="itemLists[form.autocomplete.name]"
                    :item-text="form.autocomplete['item-text']"
                    :item-value="form.autocomplete['item-value']"
                  >
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item[form.autocomplete['item-text']] }}
                      </div>
                    </template>
                    <template v-slot:selection="{ item, index }">
                      <div v-if="index === 0">
                        <span>{{ item[form.autocomplete['item-text']] }} </span>
                      </div>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ filterForm[form.model].length - 1 }} รายการ)
                      </span>
                    </template>
                  </v-autocomplete>
                  <!-- <div v-if="form.type === 'select'">{{ form.select.name }}</div> -->
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </FilterTemplateDialog>
  </div>
</template>

<script>
import FilterTemplateDialog from '../../maintenance/dialog/FilterTemplateDialog.vue'
export default {
  components: {
    FilterTemplateDialog
  },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (newVal, oldVal) {
        this.isOpenDialog = newVal
      }
    },
    'filterForm.road_id' (newVal, oldVal) {
      if (newVal) {
        delete this.filterForm.section_part_id
        this.getSectionPart(newVal)
      } else {
        delete this.filterForm.section_part_id
        this.itemLists.control = []
      }
    },
    'filterForm.ref_maintenance_group_id' (newVal, oldVal) {
      if (newVal) {
        const isMaintenanceInclude = this.maintenanceByGroup.find(
          (maintenance) => maintenance.id === this.filterForm.ref_maintenance_id
        )
        if (!isMaintenanceInclude) delete this.filterForm.ref_maintenance_id
      }
    },
    'filterForm.division' (newVal, oldVal) {
      if (newVal) {
        this.itemLists.districtByDivision = this.itemLists[
          'v-mbmms-district-exist-only'
        ].filter((district) => district.division_code === newVal)
        const checkid = this.itemLists.districtByDivision.some(
          (id) => id.distrit_code === this.filterForm.district_code
        )
        if (!checkid) {
          // delete this.filterForm.district_code
          this.filterForm.district_code = null
        }
      } else {
        // delete this.filterForm.district_code
        this.filterForm.district_code = null
        this.getRefRoad()
        this.itemLists.districtByDivision = []
      }
    },
    'filterForm.depot_code' (newVal, oldVal) {
      this.getRefRoad()
    },
    'filterForm.district_code' (newVal, oldVal) {
      this.getRefRoad()
    },
    depotByDistrict (newVal, oldVal) {
      const depotAvailable = newVal.find(
        (depot) => depot.depot_code === this.filterForm.depot_code
      )
      if (!depotAvailable) {
        this.filterForm.depot_code = null
      }
    }
  },
  data: () => ({
    isOpenDialog: true,
    filter: {},
    filterForm: {},
    province: [],
    itemLists: {
      road: [],
      control: [],
      bridgeType: [],
      province: []
    },
    formFilterField: [
      {
        name: 'ปีงบประมาณ',
        model: 'budget_year',
        placeholder: (new Date().getFullYear() + 543).toString(),
        col: 12,
        coltext: 2,
        colfield: 4,
        type: 'select',
        nodata: 'ไม่มีปีงบประมาณให้เลือก',
        select: {
          name: 'budgetYearList',
          'item-text': '',
          'item-value': ''
        }
      },
      {
        name: 'สำนัก',
        model: 'division',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'v-mbmms-division-exist-only',
          'item-text': 'name',
          'item-value': 'division_code'
        }
      },
      {
        name: 'แขวงทางหลวง',
        model: 'district_code',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        nodata: 'โปรดเลือกหมวดทางหลวง',
        select: {
          name: 'districtByDivision',
          'item-text': 'name',
          'item-value': 'district_code'
        }
      },
      {
        name: 'หมวดทางหลวง',
        model: 'depot_code',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'select',
        select: {
          name: 'v-mbmms-depot-exist-only',
          'item-text': 'name',
          'item-value': 'depot_code'
        }
      },
      {
        name: 'หมายเลขทางหลวง',
        model: 'road_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        select: {
          name: 'road',
          'item-text': 'label',
          'item-value': 'road_id'
        },
        nodata: 'ไม่มีหมายเลขทางหลวงให้เลือก'
      },
      {
        name: 'ตอนควบคุม',
        model: 'section_part_id',
        col: 6,
        coltext: 4,
        colfield: 8,
        type: 'select',
        nodata: 'โปรดเลือกหมายเลขทางหลวง',
        select: {
          name: 'control',
          'item-text': 'label',
          'item-value': 'section_part_id'
        }
      },
      {
        name: 'ชิ้นส่วน',
        model: 'ref_bridge_component_ids',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'autocomplete',
        autocomplete: {
          name: 'bridge-component',
          'item-text': 'id',
          'item-value': 'id'
        }
      },
      {
        name: 'วิธีการซ่อม',
        model: 'ref_repair_ids',
        col: 12,
        coltext: 2,
        colfield: 10,
        type: 'autocomplete',
        autocomplete: {
          name: 'repair',
          'item-text': 'name',
          'item-value': 'id'
        }
      }
    ],
    name: null,
    budgetYear: ''
  }),
  async mounted () {
    await this.getRefRoad()
    await this.getRef('repair')
    await this.getRef('bridge-component')
    await this.getRef('v-mbmms-district-exist-only')
    await this.getRef('v-mbmms-division-exist-only')
    await this.getRef('v-mbmms-depot-exist-only')
    this.filterForm = {}
    await this.getBudgetYearList()
    this.$emit('filter', this.filterForm)
  },
  computed: {
    maintenanceByGroup () {
      if (
        this.filterForm.ref_maintenance_group_id ||
        this.filterForm.ref_maintenance_id
      ) {
        return this.itemLists.maintenance.filter(
          (maintenance) =>
            maintenance.ref_maintenance_group_id ===
            this.filterForm.ref_maintenance_group_id
        )
      } else return []
    },
    depotByDistrict () {
      console.log(this.filterForm.district_code)
      if (this.itemLists['v-mbmms-depot-exist-only']) {
        if (this.filterForm.district_code) {
          return this.itemLists['v-mbmms-depot-exist-only'].filter(
            (depot) => depot.district_code === this.filterForm.district_code
          )
        } else return this.itemLists['v-mbmms-depot-exist-only']
      } else return []
    }
  },
  methods: {
    async getBudgetYearList () {
      this.itemLists.budgetYearList = await this.$store.dispatch(
        'getBudgetYearList'
      )
      const datetime = new Date()
      if (datetime.getMonth() >= 10) {
        this.filterForm.budget_year = datetime.getFullYear() + 544 // 543 + 1 budget year
      } else {
        this.filterForm.budget_year = datetime.getFullYear() + 543
      }
    },
    close () {
      this.$emit('close')
    },
    confirm () {
      const filterForm = { ...this.filterForm }
      // if (filterForm.budget_year) filterForm.budget_year -= 543
      this.$emit('filter', filterForm)
    },
    clearForm () {
      const budgetYear = this.filterForm.budget_year
      this.filterForm = {}
      this.filterForm.budget_year = budgetYear
    },
    async getRef (method) {
      this.itemLists[method] = await this.$store.dispatch('ref/getRef', {
        method
      })
    },

    async getRefRoad () {
      const filter = {
        depot_code: this.filterForm.depot_code,
        district_code: this.filterForm.district_code
      }
      const roads = await this.$store.dispatch('ref/getRef', {
        method: 'v-mbmms-road-exist-only',
        filter
      })
      for (const road of roads) {
        road.label = `${parseInt(road.code)}`
      }
      const checkid = roads.some((id) => id.road_id === this.filterForm.road_id)
      if (!checkid) {
        delete this.filterForm.road_id
        delete this.filterForm.section_part_id
        this.itemLists.control = []
      }
      this.itemLists.road = roads
    },
    async getSectionPart (roadid) {
      const controls = await this.$store.dispatch('ref/getRef', {
        method: `v-mbmms-section-part-exist-only?filters=road_id:${roadid}`
      })
      for (const control of controls) {
        control.label = `${control.code} - ${control.name}`
      }
      this.itemLists.control = controls
    }
  }
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
</style>
