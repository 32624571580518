var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"theme-bg fill-height"},[_c('div',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex pt-4 pb-3 align-center"},[_c('h2',{staticClass:"primary--text font-weight-regular"},[_vm._v(" รายละเอียดการซ่อมบำรุง ")]),_c('v-btn',{staticClass:"ml-auto",attrs:{"color":"primary"},on:{"click":function($event){_vm.exportRepairDialog = !_vm.exportRepairDialog}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-export-variant")]),_vm._v(" ส่งออก ")],1)],1),_c('div',{staticClass:"d-flex align-center mt-4"},[_c('v-text-field',{attrs:{"name":"search","id":"id","solo":"","dense":"","hide-details":"","placeholder":"ชื่อสะพาน หรือ แผนซ่อมบำรุง","prepend-inner-icon":"mdi-magnify"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getMethodBridgeRepairList.apply(null, arguments)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-btn',{staticClass:"primary elevation-2 white--text rounded ml-2",attrs:{"icon":"","tile":""},on:{"click":function($event){_vm.filterMaintenanceRepair = true}}},[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-data-table',{staticClass:"elevation-2 rounded row-pointer data-repair-table",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{
          'items-per-page-text': 'จำนวนแถว'
        },"sort-by":"budget_year","sort-desc":"","loading":_vm.isLoading},on:{"click:row":_vm.openEditMaintenanceMethodDialog},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
        var item = ref.item;
return [(item.ref_bridge_repair_status_id === '1')?_c('div',[_c('v-chip',{staticClass:"white--text",attrs:{"color":'green',"small":""}},[_vm._v(" รอจัดสรร ")])],1):_c('div',[_c('v-chip',{staticClass:"white--text",attrs:{"color":'yellow',"small":""}},[_vm._v(" ดำเนินการ ")])],1)]}},{key:"item.price",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.sum_price || 0).toLocaleString())+" ")]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name || '(ไม่ระบุชื่อ)')+" ")]}},{key:"item.bridge_name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((item.bridge_name) + " " + (item.direction_name ? ("(" + (item.direction_name) + ")") : '')))+" ")]}},{key:"item.km",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(((_vm.getKm(item.km_start || 0)) + " - " + (_vm.getKm(item.km_end || 0))))+" ")]}},{key:"item.bhi",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.bhi || 0).toLocaleString())+" ")]}},{key:"item.nbes_29_value",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.nbes_29_value || 0).toLocaleString())+" ")]}},{key:"item.nbes_28A_value",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.nbes_28A_value || 0).toLocaleString())+" ")]}},{key:"item.nbes_27_value",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.nbes_27_value || 0))+" ")]}}])})],1)]),_c('EditMaintenanceMethodDialog',{attrs:{"repairId":_vm.repairId},on:{"confirm":_vm.confirmEdit,"close":function($event){_vm.editMaintenanceMethodDialog = false}},model:{value:(_vm.editMaintenanceMethodDialog),callback:function ($$v) {_vm.editMaintenanceMethodDialog=$$v},expression:"editMaintenanceMethodDialog"}}),_c('FilterMaintenanceRepair',{on:{"filter":_vm.filterMaintenance,"close":function($event){_vm.filterMaintenanceRepair = false}},model:{value:(_vm.filterMaintenanceRepair),callback:function ($$v) {_vm.filterMaintenanceRepair=$$v},expression:"filterMaintenanceRepair"}}),_c('ExportRepairDialog',{on:{"close":function($event){_vm.exportRepairDialog = false}},model:{value:(_vm.exportRepairDialog),callback:function ($$v) {_vm.exportRepairDialog=$$v},expression:"exportRepairDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }